import { PassStatus } from "@/api/sales";
import { createRoute, lazyRouteComponent } from "@tanstack/react-router";
import { valibotValidator } from "@tanstack/valibot-adapter";
import { array, fallback, number, object, optional, pipe, string, toMinValue } from "valibot";
import { supplierRoute } from "..";

const PASS_STATUSES = ["pending", "redeemed", "expired"] as PassStatus[];

const searchSchema = object({
  page: fallback(pipe(optional(number(), 1), toMinValue(1)), 1),
  "page-size": fallback(pipe(optional(number(), 100), toMinValue(10)), 100),
  "pass-statuses": fallback(optional(array(string()), PASS_STATUSES), PASS_STATUSES),
});

export const passesRoute = createRoute({
  path: "passes",
  getParentRoute: () => supplierRoute,
  component: lazyRouteComponent(() => import("./+Passes")),
  validateSearch: valibotValidator(searchSchema),
});
