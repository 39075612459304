import {
  NavigationMenu,
  NavigationMenuHeading,
  NavigationMenuLink,
  NavigationMenuSeparator,
} from "@/components/layout/NavigationMenu";
import { Box, Select, Text } from "@givenwell/components";
import { IconArrowLeft } from "@tabler/icons-react";
import { createRoute, lazyRouteComponent, Outlet, useMatches, useNavigate } from "@tanstack/react-router";
import { valibotValidator } from "@tanstack/valibot-adapter";
import { fallback, number, object, optional, pipe, string, toMinValue, transform } from "valibot";
import { adminRoute } from "..";

export const reportsRoute = createRoute({
  path: "reports/$countryCode",
  getParentRoute: () => adminRoute,
  beforeLoad: async () => {
    return {
      sidebar: <Sidebar />,
    };
  },
});

function Sidebar() {
  const match = useMatches({ select: matches => matches[matches.length - 1]! });
  const route = match.routeId;
  const params = match.params;
  const search = match.search;
  const countryCode = "countryCode" in params ? params.countryCode : "NZ";
  const navigate = useNavigate();

  return (
    <NavigationMenu>
      <NavigationMenuLink
        icon={<IconArrowLeft />}
        activeOptions={{
          exact: true,
        }}
        to="/admin"
      >
        Back
      </NavigationMenuLink>
      <Box>
        <Select
          size="sm"
          css={{
            fontSize: 14,
          }}
          value={countryCode}
          onValueChange={value => {
            navigate({
              to: route,
              params: {
                ...params,
                countryCode: value,
              },
              search: search,
            });
          }}
        >
          <option value="AU">Australia</option>
          <option value="CA">Canada</option>
          <option value="IE">Ireland</option>
          <option value="NZ">New Zealand</option>
          <option value="SG">Singapore</option>
          <option value="UK">United Kingdom</option>
          <option value="US">United States</option>
        </Select>
      </Box>
      <NavigationMenuSeparator />
      <NavigationMenuLink
        to="/admin/reports/$countryCode"
        params={{ countryCode }}
        activeOptions={{
          exact: true,
        }}
      >
        Overview
      </NavigationMenuLink>
      <NavigationMenuHeading>Supporters</NavigationMenuHeading>
      <NavigationMenuLink params={{ countryCode }} to="/admin/reports/$countryCode/supporter">
        Monthly Stats
      </NavigationMenuLink>
      <NavigationMenuLink params={{ countryCode }} to="/admin/reports/$countryCode/supporter-detail">
        Monthly Details
      </NavigationMenuLink>
      <NavigationMenuLink params={{ countryCode }} to="/admin/reports/$countryCode/supporter-upcoming">
        Upcoming Allowances
      </NavigationMenuLink>
      <NavigationMenuLink
        params={{ countryCode }}
        search={{ view: "details", from: undefined!, to: undefined! }}
        to="/admin/reports/$countryCode/supporter-sales"
      >
        Sales
      </NavigationMenuLink>
      <NavigationMenuSeparator />
      <NavigationMenuHeading>Suppliers</NavigationMenuHeading>
      {/* <NavigationMenuLink params={{ countryCode }} to="/admin/reports/$countryCode/coverage">
        Listing Coverage
      </NavigationMenuLink> */}
      <NavigationMenuLink params={{ countryCode }} to="/admin/reports/$countryCode/supplier-onboarding-progress">
        Onboarding Progress
      </NavigationMenuLink>
      <NavigationMenuLink params={{ countryCode }} to="/admin/reports/$countryCode/listing-summary">
        Listing Summary
      </NavigationMenuLink>
      <NavigationMenuLink params={{ countryCode }} to="/admin/reports/$countryCode/daily-payouts">
        Payouts
      </NavigationMenuLink>
      <NavigationMenuLink params={{ countryCode }} to="/admin/reports/$countryCode/partial-self-sign-ups">
        Partial Sign-Ups
      </NavigationMenuLink>
      <NavigationMenuSeparator />
      <NavigationMenuHeading>Members</NavigationMenuHeading>
      <NavigationMenuLink params={{ countryCode }} to="/admin/reports/$countryCode/member-subscriptions">
        Subscriptions
      </NavigationMenuLink>
      <NavigationMenuSeparator />
      <NavigationMenuHeading>Other</NavigationMenuHeading>
      <NavigationMenuLink params={{ countryCode }} to="/admin/reports/$countryCode/themes-summary">
        Themes Content
      </NavigationMenuLink>
      <NavigationMenuLink params={{ countryCode }} to="/admin/reports/$countryCode/airwallex">
        Airwallex
      </NavigationMenuLink>
      <Text css={{ fontScale: "2xs", opacity: 0.5 }}>
        All reports include data from Givenwell-affiliated Supporters, Suppliers, Members, and bank accounts, unless
        stated otherwise.
      </Text>
    </NavigationMenu>
  );
}

export const reportsIndexRoute = createRoute({
  path: "/",
  getParentRoute: () => reportsRoute,
  component: lazyRouteComponent(() => import("./+Reports")),
});

export const reportsAirwallexRoute = createRoute({
  path: "airwallex",
  getParentRoute: () => reportsRoute,
  component: lazyRouteComponent(() => import("./+Airwallex")),
  beforeLoad: () => ({
    breadcrumb: "Airwallex",
  }),
});

export const coverageRoute = createRoute({
  path: "coverage",
  getParentRoute: () => reportsRoute,
  component: lazyRouteComponent(() => import("./+CoveragePage")),
  beforeLoad: () => ({
    breadcrumb: "Listing Coverage",
  }),
});

export const supporterMonthlyStatsReportRoute = createRoute({
  path: "supporter",
  getParentRoute: () => reportsRoute,
  component: lazyRouteComponent(() => import("./+SupporterMonthlyStatsReportPage")),
  beforeLoad: () => ({
    breadcrumb: "Supporter Monthly Stats",
  }),
});

export const supporterMonthlyDetailsReportRoute = createRoute({
  path: "supporter-detail",
  getParentRoute: () => reportsRoute,
  component: lazyRouteComponent(() => import("./+SupporterMonthlyDetailsReportPage")),
  beforeLoad: () => ({
    breadcrumb: "Supporter Monthly Details",
  }),
});

export const supplierOnboardingProgressReportRoute = createRoute({
  path: "supplier-onboarding-progress",
  getParentRoute: () => reportsRoute,
  component: lazyRouteComponent(() => import("./+SupplierOnboardingProgressReportPage")),
  beforeLoad: () => ({
    breadcrumb: "Supporter Onboarding Progress",
  }),
});

export const listingSummaryReportRoute = createRoute({
  path: "listing-summary",
  getParentRoute: () => reportsRoute,
  component: lazyRouteComponent(() => import("./+ListingSummaryReportPage")),
  beforeLoad: () => ({
    breadcrumb: "Listing Summary Report",
  }),
});

export const supplierPartialSelfSignUpsRoute = createRoute({
  path: "partial-self-sign-ups",
  getParentRoute: () => reportsRoute,
  component: lazyRouteComponent(() => import("./+PartialSelfSignUpsReportPage")),
  beforeLoad: () => ({
    breadcrumb: "Partial Self Sign-Ups Report",
  }),
});

export const themesSummaryRoute = createRoute({
  path: "themes-summary",
  getParentRoute: () => reportsRoute,
  component: lazyRouteComponent(() => import("./+ThemesReport")),
  beforeLoad: () => ({
    breadcrumb: "Themes Summary Report",
  }),
});

const searchSchema = object({
  q: fallback(optional(string()), ""),
  page: fallback(pipe(optional(number(), 1), toMinValue(1)), 1),
  "page-size": fallback(pipe(optional(number(), 100), toMinValue(10)), 100),
});

export const dailyPayoutsRoute = createRoute({
  path: "daily-payouts",
  getParentRoute: () => reportsRoute,
  beforeLoad: () => ({
    breadcrumb: "Daily Payouts",
  }),
  component: Outlet,
});

export const dailyPayoutsIndexRoute = createRoute({
  path: "/",
  getParentRoute: () => dailyPayoutsRoute,
  component: lazyRouteComponent(() => import("./+DailyPayouts")),
  validateSearch: valibotValidator(searchSchema),
  beforeLoad: () => ({
    breadcrumb: "Daily Payouts",
  }),
});

const dailyPayoutsDetailSchema = object({
  from: fallback(pipe(optional(string()), transform(optionalStringToOptionalDate)), undefined),
  to: fallback(pipe(optional(string()), transform(optionalStringToOptionalDate)), undefined),
});

export const dailyPayoutsDetailRoute = createRoute({
  path: "detail",
  getParentRoute: () => dailyPayoutsRoute,
  component: lazyRouteComponent(() => import("./daily-payouts/+DailyPayoutsDetail")),
  validateSearch: valibotValidator(dailyPayoutsDetailSchema),
  beforeLoad: () => ({
    breadcrumb: "Transactions",
  }),
});

const memberSubscriptionsSchema = object({
  status: fallback(optional(string(), ""), ""),
  page: fallback(pipe(optional(number(), 1), toMinValue(1)), 1),
  "page-size": fallback(pipe(optional(number(), 100), toMinValue(10)), 100),
});

export const memberSubscriptionsRoute = createRoute({
  path: "member-subscriptions",
  getParentRoute: () => reportsRoute,
  component: lazyRouteComponent(() => import("./+MemberSubscriptionsReportPage")),
  validateSearch: valibotValidator(memberSubscriptionsSchema),
  beforeLoad: () => ({
    breadcrumb: "Member Subscriptions",
  }),
});

const upcomingAllowancesSchema = object({
  page: fallback(pipe(optional(number(), 1), toMinValue(1)), 1),
  "page-size": fallback(pipe(optional(number(), 100), toMinValue(10)), 100),
});

export const supporterUpcomingAllowancesRoute = createRoute({
  path: "supporter-upcoming",
  getParentRoute: () => reportsRoute,
  component: lazyRouteComponent(() => import("./+SupporterUpcomingAllowancesReportPage")),
  validateSearch: valibotValidator(upcomingAllowancesSchema),
  beforeLoad: () => ({
    breadcrumb: "Supporter — Upcoming Allowances",
  }),
});

export type SupporterSalesViews = "details" | "by_supplier" | "by_supporter";

const supporterSalesRouteSchema = object({
  from: fallback(pipe(optional(string()), transform(optionalStringToOptionalDate)), undefined),
  to: fallback(pipe(optional(string()), transform(optionalStringToOptionalDate)), undefined),
  supplier_id: fallback(optional(string()), ""),
  supporter_id: fallback(optional(string()), ""),
  view: fallback(string(), "details"),
});
export const supporterSalesRoute = createRoute({
  path: "supporter-sales",
  getParentRoute: () => reportsRoute,
  component: lazyRouteComponent(() => import("./+SupporterSalesReportPage")),
  validateSearch: valibotValidator(supporterSalesRouteSchema),
  beforeLoad: () => ({
    breadcrumb: "Supporter — Sales",
  }),
});

function optionalStringToOptionalDate(dateStr: string = "") {
  const date = new Date(dateStr);
  if (!isNaN(date.getTime())) {
    return date;
  }
  return undefined;
}
