type Environments = "dev" | "test" | "demo" | "prod";

export function detectEnvironment(): Environments {
  if (import.meta.env.DEV) return "dev";

  if (import.meta.env.VITE_TEST) return "test";

  if (import.meta.env.VITE_DEMO) return "demo";

  return "prod";
}

type Config = {
  environmentName: Environments;
  apiBaseUrl: string;
  marketplaceUrl: string;
  manageUrl: string;
  cookieDomain: string | undefined;
  mapboxPublicAccessToken: string;
};

const MAPBOX_BEN = "pk.eyJ1IjoiZ2l2ZW53ZWxsYmVuIiwiYSI6ImNsbnNjZnhidjB5OXEyc253c3A2ZXpnYXUifQ.ImW-5vpX25P2j7pp05baKg";

const CONFIG: Record<Environments, Config> = {
  dev: {
    environmentName: "dev",
    apiBaseUrl: `https://${location.hostname}:3000`,
    marketplaceUrl: `https://${location.hostname}:${location.port}`,
    manageUrl: `https://${location.hostname}:${location.port}`,
    // apiBaseUrl: "https://api.test.givenwell.com",
    // marketplaceUrl: "https://marketplace.test.givenwell.com",
    // manageUrl: "https://manage.test.givenwell.com",
    // apiBaseUrl: "https://api.givenwell.com",
    // marketplaceUrl: "https://marketplace.givenwell.com",
    // manageUrl: "https://manage.givenwell.com",
    cookieDomain: undefined,
    mapboxPublicAccessToken: MAPBOX_BEN,
  },
  test: {
    environmentName: "test",
    apiBaseUrl: "https://api.test.givenwell.com",
    marketplaceUrl: "https://marketplace.test.givenwell.com",
    manageUrl: "https://manage.test.givenwell.com",
    cookieDomain: ".test.givenwell.com",
    mapboxPublicAccessToken: MAPBOX_BEN,
  },
  demo: {
    environmentName: "demo",
    apiBaseUrl: "https://api.demo.givenwell.com",
    marketplaceUrl: "https://marketplace.demo.givenwell.com",
    manageUrl: "https://manage.demo.givenwell.com",
    cookieDomain: ".demo.givenwell.com",
    mapboxPublicAccessToken: MAPBOX_BEN,
  },
  prod: {
    environmentName: "prod",
    apiBaseUrl: "https://api.givenwell.com",
    marketplaceUrl: "https://marketplace.givenwell.com",
    manageUrl: "https://manage.givenwell.com",
    cookieDomain: ".givenwell.com",
    mapboxPublicAccessToken: MAPBOX_BEN,
  },
};

export const config = CONFIG[detectEnvironment()];
