import { AvailableTimeTransformer } from "@/pages/supplier/settings/calendars/AvailableTimeTransformer";
import { ExternalCalendarModel } from "@/pages/supplier/settings/calendars/[calendarId]/model";
import { CalendarModel } from "@/pages/supplier/settings/calendars/model";
import {
  ApiError,
  CreateSupplierCalendarBody,
  GetSupplierProfileResponse,
  InviteSupplierStaffBody,
  SetCalendarAvailabilityOverrideBody,
  UpdateCancellationPolicyRequest,
  UpdateSupplierBody,
  UpdateSupplierCalendarBody,
  UpdateSupplierProfileBody,
  UpdateSupplierSettingsBody,
  UpsertSupplierLocationBody,
} from "@givenwell/management-api";
import { queryOptions, useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { InferOutput, boolean, fallback, object, parse } from "valibot";
import { ListingStatus } from "./listings";
import { api, queryClient } from "./utils";

export function useSearchSuppliersQuery(searchTerm: string, page = 1, pageSize = 10, country?: string) {
  return useQuery({
    queryKey: ["suppliers-search", { searchTerm, page, pageSize, country }],
    queryFn: async () => {
      const response = await api.supplier.searchSuppliers({ page, pageSize, query: searchTerm, country });
      return response;
    },
    placeholderData: previousData => previousData,
  });
}

export const supplierQuery = (supplierId: string) =>
  queryOptions({
    queryKey: ["suppliers", supplierId],
    queryFn: async () => {
      return await api.supplier.getSupplier({ supplierId });
    },
  });

export function useSupplierQuery(supplierId: string) {
  return useQuery(supplierQuery(supplierId));
}

export const listingsQuery = (supplierId: string, status: ListingStatus | undefined) => {
  return queryOptions({
    queryKey: ["suppliers", supplierId, "listings", { status }],
    queryFn: async () => {
      const response = await api.supplier.getListings({ supplierId, status });
      return response.listings;
    },
    placeholderData: prev => prev,
  });
};

export function useListingsQuery(supplierId: string, status: ListingStatus | undefined) {
  return useQuery(listingsQuery(supplierId, status));
}

export function useSupplierLocationsQuery(supplierId: string) {
  return useQuery({
    queryKey: ["suppliers", supplierId, "locations"],
    queryFn: () => api.supplier.getSupplierLocations({ supplierId }),
    select: response => response.locations,
  });
}

export function useSupplierLocationQuery(supplierId: string, locationId: string | undefined) {
  return useQuery({
    queryKey: ["suppliers", supplierId, "locations"],
    queryFn: () => api.supplier.getSupplierLocations({ supplierId }),
    enabled: locationId !== undefined,
    select: response => {
      return response.locations.find(location => location.location_id === locationId);
    },
  });
}

export function useCalendarsQuery(supplierId: string) {
  return useQuery({
    queryKey: ["suppliers", supplierId, "calendars"],
    queryFn: () => api.supplier.getCalendars({ supplierId }),
    enabled: supplierId !== undefined,
    select: (data): CalendarModel[] => {
      return data.calendars.map(cal => {
        const externalCalendar: ExternalCalendarModel | undefined =
          cal.external_calendar ?
            {
              calendarId: cal.calendar_id,
              externalCalendarId: cal.external_calendar.id,
              provider: cal.external_calendar.provider,
              name: cal.external_calendar.name,
            }
          : undefined;

        return {
          id: cal.calendar_id,
          resourceName: cal.resource_name,
          externalCalendar: externalCalendar,
          settings: {
            advanceBookingDays: cal.booking_config.advance_booking_days,
            intervalMinutes: cal.booking_config.interval_minutes,
            minimumNoticeTimeHours: roundUpToNearestWholeHours(cal.booking_config.minimum_notice_time_minutes),
            slottingMethod: cal.booking_config.slotting_method,
            timezone: cal.booking_config.timezone,
            availableDays: AvailableTimeTransformer.toAvailableTimeDayModels(cal.booking_config.available_times),
          },
        };
      });
    },
  });

  function roundUpToNearestWholeHours(minimumNoticeTimeMinutes: number): number {
    const wholeHours = Math.ceil(minimumNoticeTimeMinutes / 60);
    return wholeHours;
  }
}

export function useSettingsQuery(supplierId: string) {
  return useQuery({
    queryKey: ["suppliers", supplierId, "settings"],
    queryFn: () => api.supplier.getSettings({ supplierId }),
  });
}

export const walletsQuery = (supplierId: string) =>
  queryOptions({
    queryKey: ["suppliers", supplierId, "wallets"],
    queryFn: () => api.supplier.getWallets({ supplierId }),
  });

export function useWalletsQuery(supplierId: string) {
  return useQuery(walletsQuery(supplierId));
}

export function useCalendarEventsQuery(from: Date, to: Date, supplierId: string) {
  const roundedFrom = new Date(from);
  roundedFrom.setHours(0, 0, 0, 0);

  const roundedTo = new Date(to);
  roundedTo.setHours(24, 0, 0, 0);

  return useQuery({
    queryKey: [
      "suppliers",
      supplierId,
      "calendar-events",
      {
        from: roundedFrom.toISOString(),
        to: roundedTo.toISOString(),
      },
    ],
    queryFn: () =>
      api.supplier.getCalendarEvents({ from: roundedFrom.toISOString(), to: roundedTo.toISOString(), supplierId }),
    placeholderData: prev => prev,
  });
}

export function useSupplierProfileQuery(supplierId: string) {
  return useQuery({
    queryKey: ["suppliers", supplierId, "profile"],
    queryFn: async () => {
      try {
        const response = await api.supplier.getSupplierProfile({ supplierId });
        return response;
      } catch (err: unknown) {
        const apiError = err as ApiError;
        if (apiError.status === 404) {
          const emptyResponse: GetSupplierProfileResponse = {
            bio: "",
          };
          return emptyResponse;
        } else {
          throw err;
        }
      }
    },
  });
}

export function useSupplierStaffQuery(supplierId: string) {
  return useQuery({
    queryKey: ["suppliers", supplierId, "staff"],
    queryFn: () => api.supplier.getSupplierStaff({ supplierId }),
    select: response => response.staff_users,
  });
}

export function useSupplierTokenMetadataQuery(supplierId: string) {
  return useQuery({
    queryKey: ["suppliers", supplierId, "token_metadata"],
    queryFn: async () => {
      const [settings, metadata] = await Promise.all([
        api.supplier.getSettings({ supplierId }),
        api.metadata.getTokensMetadata(),
      ]);
      const tokens = metadata.tokens;
      const tokenCurrencyCode = settings.primary_token_currency;
      const tokenMetadata = tokens.find(token => token.code === tokenCurrencyCode);

      if (!tokenMetadata) throw new Error("No token currency code found");

      return tokenMetadata;
    },
  });
}

// -------------------------------------------------------------------------------------------------

export function useUpdateSupplierMutation(supplierId: string) {
  return useMutation({
    mutationFn: async (body: UpdateSupplierBody) => {
      await api.supplier.updateSupplier({ supplierId, requestBody: body });
    },
  });
}

export function useUpdateSupplierProfileMutation(supplierId: string) {
  return useMutation({
    mutationFn: async (body: UpdateSupplierProfileBody) => {
      await api.supplier.updateSupplierProfile({ supplierId, requestBody: body });
    },
  });
}

export function useUpdateSupplierSettingsMutation() {
  return useMutation({
    mutationFn: async (args: { supplierId: string; body: UpdateSupplierSettingsBody }) => {
      const supplierId = args.supplierId;
      return await api.supplier.updateSupplierSettings({ supplierId, requestBody: args.body });
    },
  });
}

export function useSetCalendarAvailabilityOverrideMutation(supplierId: string) {
  return useMutation({
    mutationFn: async ({
      date,
      calendarId,
      body,
    }: {
      date: string;
      calendarId: string;
      body: SetCalendarAvailabilityOverrideBody;
    }) => {
      await api.supplier.setCalendarAvailabilityOverride({ date, supplierId, calendarId, requestBody: body });
    },
  });
}

export function useDeleteCalendarAvailabilityOverrideMutation(supplierId: string) {
  return useMutation({
    mutationFn: async ({ date, calendarId }: { date: string; calendarId: string }) => {
      await api.supplier.deleteCalendarAvailabilityOverride({ date, supplierId, calendarId });
    },
  });
}

export function useCreateLocationMutation() {
  return useMutation({
    mutationFn: async (args: { supplierId: string; body: UpsertSupplierLocationBody }) => {
      return await api.supplier.createLocation({ supplierId: args.supplierId, requestBody: args.body });
    },
  });
}

export function useUpdateLocationMutation() {
  const client = useQueryClient();

  return useMutation({
    mutationFn: async (args: { supplierId: string; locationId: string; body: UpsertSupplierLocationBody }) => {
      await api.supplier.updateLocation({
        supplierId: args.supplierId,
        locationId: args.locationId,
        requestBody: args.body,
      });
    },
    onSuccess: () => {
      client.removeQueries({ queryKey: ["locations"] });
    },
  });
}

export function useInviteSupplierStaffMutation() {
  return useMutation({
    mutationFn: async (args: { supplierId: string; request: InviteSupplierStaffBody }) => {
      return await api.supplier.inviteSupplierStaff({ supplierId: args.supplierId, requestBody: args.request });
    },
  });
}

export function useUpdateCalendarMutation() {
  const client = useQueryClient();

  return useMutation({
    mutationFn: async (args: { supplierId: string; calendarId: string; request: UpdateSupplierCalendarBody }) => {
      return await api.supplier.updateCalendar({
        supplierId: args.supplierId,
        calendarId: args.calendarId,
        requestBody: args.request,
      });
    },
    onSuccess: (_, variables) => {
      client.invalidateQueries({ queryKey: ["suppliers", variables.supplierId, "calendars"] });
      client.invalidateQueries({ queryKey: ["suppliers", variables.supplierId, "calendar-events"] });
    },
  });
}

export function useCreateCalendarMutation() {
  const client = useQueryClient();

  return useMutation({
    mutationFn: async (args: { supplierId: string; request: CreateSupplierCalendarBody }) => {
      return await api.supplier.createCalendar({ supplierId: args.supplierId, requestBody: args.request });
    },
    onSuccess: (_, variables) => {
      client.invalidateQueries({ queryKey: ["suppliers", variables.supplierId, "calendars"] });
      client.invalidateQueries({ queryKey: ["suppliers", variables.supplierId, "calendar-events"] });
    },
  });
}

export function useUpdateCancellationPolicyMutation() {
  const client = useQueryClient();

  return useMutation({
    mutationFn: async (args: { supplierId: string; policyId: string; body: UpdateCancellationPolicyRequest }) => {
      if (args.policyId) {
        await api.supplier.updateCancellationPolicy({
          supplierId: args.supplierId,
          cancellationPolicyId: args.policyId,
          requestBody: args.body,
        });
      }
    },
    onSuccess: () => {
      client.removeQueries({ queryKey: ["cancellation-policy"] });
    },
  });
}

export function useDeleteSupplierLocationMutation() {
  const client = useQueryClient();

  return useMutation({
    mutationFn: async (args: { supplierId: string; locationId: string }) => {
      await api.supplier.deleteLocation({ supplierId: args.supplierId, locationId: args.locationId });
    },
    onSuccess: (_, variables) => {
      client.invalidateQueries({ queryKey: ["suppliers", variables.supplierId, "calendars"] });
    },
  });
}

export function useDeleteSupplierCalendarMutation() {
  const client = useQueryClient();

  return useMutation({
    mutationFn: async (args: { supplierId: string; calendarId: string }) => {
      await api.supplier.deleteCalendar({ supplierId: args.supplierId, calendarId: args.calendarId });
    },
    onSuccess: (_, variables) => {
      client.invalidateQueries({ queryKey: ["suppliers", variables.supplierId, "calendars"] });
    },
  });
}

export function useDeleteTeamMemberMutation() {
  const client = useQueryClient();

  return useMutation({
    mutationFn: async (args: { supplierId: string; userId: string }) => {
      await api.supplier.deleteSupplierStaff({ supplierId: args.supplierId, userId: args.userId });
    },
    onSuccess: (_, variables) => {
      client.invalidateQueries({ queryKey: ["suppliers", variables.supplierId, "staff"] });
    },
  });
}

export function useDeleteSupplierMutation() {
  const client = useQueryClient();

  return useMutation({
    mutationFn: async (args: { supplierId: string }) => {
      await api.supplier.deleteSupplier({ supplierId: args.supplierId });
    },
    onSuccess: (_, variables) => {
      client.invalidateQueries({ queryKey: ["suppliers", variables.supplierId] });
      setTimeout(() => {
        // Allow time for the search index to update.
        client.invalidateQueries({ queryKey: ["suppliers-search"] });
      }, 2000);
    },
  });
}

export function useResendSupplierStaffInvitationMutation() {
  return useMutation({
    mutationFn: async ({ supplierId, invitationId }: { supplierId: string; invitationId: string }) => {
      await api.supplier.resendSupplierStaffInvitation({ supplierId, invitationId });
    },
  });
}

const onboardingStateSchema = object({
  is_complete: fallback(boolean(), false),
  calendar_is_complete: fallback(boolean(), false),
});

export type OnboardingState = InferOutput<typeof onboardingStateSchema>;

export function onboardingStateQuery(supplierId: string) {
  return queryOptions({
    queryKey: ["onboarding-state", supplierId],
    queryFn: async () => {
      const res = await api.supplier.getOnboardingState({ supplierId });
      const jsonStr = res.state_json;
      let json = jsonStr ? JSON.parse(jsonStr) : {};
      if (typeof json !== "object") {
        json = {};
      }
      const state = parse(onboardingStateSchema, json);
      return state;
    },
  });
}

export function useOnboardingStateMutation() {
  return useMutation({
    mutationFn: async ({ supplierId, state }: { supplierId: string; state: OnboardingState }) => {
      const jsonStr = JSON.stringify(state);
      await api.supplier.updateOnboardingState({
        supplierId,
        requestBody: {
          state_json: jsonStr,
        },
      });
    },
    onSuccess(data, vars) {
      queryClient.setQueryData(onboardingStateQuery(vars.supplierId).queryKey, data => {
        if (!data) return data;
        return { ...data, ...vars.state };
      });
    },
  });
}

export const legalQuery = (supplierId: string) =>
  queryOptions({
    queryKey: ["suppliers", supplierId, "legal"],
    queryFn: async () => api.supplier.getLegal({ supplierId }),
  });

export const defaultLegalQuery = (supplierId: string) =>
  queryOptions({
    queryKey: ["suppliers", supplierId, "default-legal"],
    queryFn: async () => api.supplier.getDefaultLegal({ supplierId }),
  });

export function useUpdateLegalMutation() {
  return useMutation({
    mutationFn: (args: { supplierId: string; terms: string }) => {
      return api.supplier.updateLegal({ supplierId: args.supplierId, requestBody: { terms_of_sale: args.terms } });
    },
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries({
        queryKey: ["suppliers", variables.supplierId, "legal"],
      });
    },
  });
}

export function useHideSelfSignUpMutation() {
  return useMutation({
    mutationFn: async (id: string) => {
      await api.supplier.hideSelfSignUp({ id: id });
    },
  });
}
