import { GetCountriesResponse } from "@givenwell/management-api";
import { queryOptions, useQuery } from "@tanstack/react-query";
import { SimpleUseQueryOptions, api } from "./utils";

export const countriesQuery = queryOptions({
  queryKey: ["countries"],
  queryFn: async () => api.location.getCountries(),
});

export function useReportingCountriesQuery(opts?: SimpleUseQueryOptions<GetCountriesResponse>) {
  return useQuery({
    ...opts,
    queryKey: ["countries"],
    queryFn: async () => api.location.getCountries(),
    select: data => data.countries.filter(c => ["NZ", "AU"].includes(c.code)),
  });
}

export function useRegionsQuery(countryCode: string | undefined) {
  return useQuery({
    queryKey: ["regions", countryCode],
    queryFn: async () => (countryCode ? (await api.location.getRegions({ countryCode: countryCode })).regions : []),
  });
}

export function useLocationTypesQuery() {
  return useQuery({
    queryKey: ["location-types"],
    queryFn: async () => (await api.location.getLocationTypes()).types,
  });
}

export const timezonesQuery = (countryCode: string) =>
  queryOptions({
    queryKey: ["timezones", countryCode],
    queryFn: async () => (countryCode ? (await api.location.getTimezones({ countryCode })).timezones : []),
  });
