import {
  NavigationMenu,
  NavigationMenuHeading,
  NavigationMenuLink,
  NavigationMenuSeparator,
} from "@/components/layout/NavigationMenu";
import { membersIndexRoute, membersRoute } from "@/pages/admin/members";
import { paymentsIndexRoute, paymentsRoute } from "@/pages/admin/payments";
import {
  coverageRoute,
  dailyPayoutsDetailRoute,
  dailyPayoutsIndexRoute,
  dailyPayoutsRoute,
  listingSummaryReportRoute,
  memberSubscriptionsRoute,
  reportsAirwallexRoute,
  reportsIndexRoute,
  reportsRoute,
  supplierOnboardingProgressReportRoute,
  supplierPartialSelfSignUpsRoute,
  supporterMonthlyDetailsReportRoute,
  supporterMonthlyStatsReportRoute,
  supporterSalesRoute,
  supporterUpcomingAllowancesRoute,
  themesSummaryRoute,
} from "@/pages/admin/reports";
import { surplusesIndexRoute, surplusesRoute } from "@/pages/admin/surpluses";
import { checkAdminRole, checkAuth } from "@/utils/router";
import {
  IconBuildingStore,
  IconBusinessplan,
  IconCashRegister,
  IconCategory,
  IconCode,
  IconCreditCard,
  IconHome,
  IconLayoutList,
  IconReport,
  IconUser,
  IconUsersGroup,
  IconVideo,
} from "@tabler/icons-react";
import { createRoute, lazyRouteComponent } from "@tanstack/react-router";
import { rootRoute } from "..";
import { accountOwnersIndexRoute, accountOwnersRoute } from "./account-owners";
import { devToolsIndexRoute, devToolsRoute } from "./devtools";
import { airwallexRoute } from "./devtools/airwallex";
import { categoriesRoute } from "./devtools/categories";
import { regionsRoute } from "./devtools/regions";
import { resourceCategoriesRoute } from "./devtools/resource-categories";
import { tokensRoute } from "./devtools/tokens";
import { uiLibraryRoute } from "./devtools/ui";
import { listingReviewDetailRoute, listingReviewIndexRoute, listingReviewRoute } from "./listing-review";
import { resourcesIndexRoute, resourcesRoute } from "./resources";
import { resourceRoute } from "./resources/[resourceId]";
import { suppliersIndexRoute, suppliersRoute } from "./suppliers";
import { supplierIndexRoute, supplierRoute } from "./suppliers/[supplierId]";
import { supplierCalendarsIndexRoute, supplierCalendarsRoute } from "./suppliers/[supplierId]/calendars";
import { supplierLocationsRoute } from "./suppliers/[supplierId]/locations";
import { supplierSettingsRoute } from "./suppliers/[supplierId]/settings";
import { supportersIndexRoute, supportersRoute } from "./supporters";
import { supporterRoute } from "./supporters/[supporterId]";
import { themesRoute } from "./themes";
import { userSettingsRoute } from "./user-settings";

export const adminRoute = createRoute({
  path: "admin",
  getParentRoute: () => rootRoute,
  beforeLoad: async () => {
    await checkAuth();
    await checkAdminRole();
    return {
      sidebar: (
        <NavigationMenu>
          <NavigationMenuLink to="/admin" icon={<IconHome />} activeOptions={{ exact: true }}>
            Overview
          </NavigationMenuLink>
          <NavigationMenuSeparator />
          <NavigationMenuHeading>Moderation</NavigationMenuHeading>
          <NavigationMenuLink to="/admin/listing-review" icon={<IconCategory />}>
            Listing Review
          </NavigationMenuLink>
          <NavigationMenuLink to="/admin/account-owners" icon={<IconUser />}>
            Account Owners
          </NavigationMenuLink>
          <NavigationMenuLink to="/admin/supporters" icon={<IconBusinessplan />}>
            Supporters
          </NavigationMenuLink>
          <NavigationMenuLink to="/admin/suppliers" icon={<IconBuildingStore />}>
            Suppliers
          </NavigationMenuLink>
          <NavigationMenuLink to="/admin/members" icon={<IconUsersGroup />}>
            Members
          </NavigationMenuLink>
          <NavigationMenuLink to="/admin/surpluses" icon={<IconCashRegister />}>
            Surpluses
          </NavigationMenuLink>
          <NavigationMenuSeparator />
          <NavigationMenuHeading>Design</NavigationMenuHeading>
          <NavigationMenuLink to="/admin/themes" icon={<IconLayoutList />}>
            Themes
          </NavigationMenuLink>
          <NavigationMenuLink to="/admin/resources" icon={<IconVideo />}>
            Resources
          </NavigationMenuLink>
          <NavigationMenuSeparator />
          <NavigationMenuHeading>Analysis</NavigationMenuHeading>
          <NavigationMenuLink to="/admin/reports/$countryCode" params={{ countryCode: "NZ" }} icon={<IconReport />}>
            Reports
          </NavigationMenuLink>
          <NavigationMenuLink to="/admin/payments" icon={<IconCreditCard />}>
            Card Payments
          </NavigationMenuLink>
          <NavigationMenuSeparator />
          <NavigationMenuLink to="/admin/devtools" icon={<IconCode />}>
            DevTools
          </NavigationMenuLink>
        </NavigationMenu>
      ),
    };
  },
  component: lazyRouteComponent(() => import("./+Layout")),
});

export const adminIndexRoute = createRoute({
  path: "/",
  getParentRoute: () => adminRoute,
  component: lazyRouteComponent(() => import("./+AdminHome")),
});

// prettier-ignore
export const adminRouteTree = adminRoute.addChildren({
  adminIndexRoute,
  devToolsRoute: devToolsRoute.addChildren({
    devToolsIndexRoute,
    airwallexRoute,
    categoriesRoute,
    resourceCategoriesRoute,
    regionsRoute,
    tokensRoute,
    uiLibraryRoute
  }),
  listingReviewRoute: listingReviewRoute.addChildren({
    listingReviewIndexRoute,
    listingReviewDetailRoute,
  }),
  reportsRoute: reportsRoute.addChildren({
    reportsIndexRoute,
    reportsAirwallexRoute,
    supporterMonthlyStatsReportRoute,
    supporterMonthlyDetailsReportRoute,
    supplierOnboardingProgressReportRoute,
    listingSummaryReportRoute,
    dailyPayoutsRoute: dailyPayoutsRoute.addChildren({
      dailyPayoutsIndexRoute,
      dailyPayoutsDetailRoute
    }),
    coverageRoute,
    supplierPartialSelfSignUpsRoute,
    memberSubscriptionsRoute,
    supporterUpcomingAllowancesRoute,
    supporterSalesRoute,
    themesSummaryRoute,
  }),
  resourcesRoute: resourcesRoute.addChildren({
    resourcesIndexRoute,
    resourceRoute,
  }),
  suppliersRoute: suppliersRoute.addChildren({
    suppliersIndexRoute,
    supplierRoute: supplierRoute.addChildren({
      supplierIndexRoute,
      supplierCalendarsRoute: supplierCalendarsRoute.addChildren({
        supplierCalendarsIndexRoute,
      }),
      supplierLocationsRoute,
      supplierSettingsRoute,
    }),
  }),
  surplusesRoute: surplusesRoute.addChildren({
    surplusesIndexRoute,
  }),
  supportersRoute: supportersRoute.addChildren({
    supportersIndexRoute,
    supporterRoute,
  }),
  themesRoute,
  membersRoute: membersRoute.addChildren({
    membersIndexRoute,
  }),
  userSettingsRoute,
  accountOwnersRoute: accountOwnersRoute.addChildren({
    accountOwnersIndexRoute
  }),
  paymentsRoute: paymentsRoute.addChildren({
    paymentsIndexRoute
  })
});
