import { Center, Spinner } from "@givenwell/components";
import { createRouter } from "@tanstack/react-router";
import { NotFound } from "./components/NotFound";
import { homeRoute, rootRoute } from "./pages";
import { ErrorComponent } from "./pages/+500";
import { adminRouteTree } from "./pages/admin";
import { dashboardRoute } from "./pages/dashboard";
import { forgotPasswordRoute } from "./pages/forgot-password";
import { activateInvitationRoute } from "./pages/invitation/activate";
import { newOrganisationRoute } from "./pages/new";
import { newAccountRoute } from "./pages/new-account";
import { newSupplierRoute } from "./pages/new-supplier";
import { signInRoute } from "./pages/sign-in";
import { supplierRouteTree } from "./pages/supplier";
import { newListingRoute } from "./pages/supplier/listings/new";
import { supporterRouteTree } from "./pages/supporter";

// prettier-ignore
const routeTree = rootRoute.addChildren({
  homeRoute,
  signInRoute,
  forgotPasswordRoute,
  newOrganisationRoute,
  newAccountRoute,
  newSupplierRoute,
  activateInvitationRoute,
  dashboardRoute,

  adminRouteTree,
  supporterRouteTree,
  supplierRouteTree,

  newListingRoute,
});

export const router = createRouter({
  routeTree,
  defaultErrorComponent: ErrorComponent,
  defaultNotFoundComponent: NotFound,
  defaultPendingMs: 0,
  defaultPendingComponent: () => (
    <Center css={{ flex: "1 0 0px" }}>
      <Spinner />
    </Center>
  ),
  notFoundMode: "fuzzy",
  defaultPreload: "intent",
  defaultPreloadDelay: 100,
});

if (import.meta.env.PROD) {
  router.subscribe("onResolved", event => {
    if (event.pathChanged) {
      import("./third-party")
        .then(module => {
          module.capturePageView(window.location.origin + event.toLocation.href);
        })
        .catch(console.error);
    }
  });
}

declare module "@tanstack/react-router" {
  interface Register {
    router: typeof router;
  }
}
