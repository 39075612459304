import { getErrorMessage } from "@/utils/error";
import { toast } from "@givenwell/components";
import {
  ActionSupporterSurplusBody,
  ApiError,
  CreateSupporterBody,
  CreateSupporterMemberBody,
  CreateSupporterMembersBody,
  InviteSupporterStaffBody,
  UpdateSupporterAllowanceSettingsBody,
  UpdateSupporterBillingDetailsBody,
  UpdateSupporterBody,
  UpdateSupporterEapSettingsBody,
} from "@givenwell/management-api";
import { queryOptions, useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { api } from "./utils";

export function useSupporterQuery(supporterId: string | undefined) {
  return useQuery({
    queryKey: ["supporters", supporterId],
    queryFn: () => api.supporter.getSupporter({ supporterId: supporterId! }),
    enabled: !!supporterId,
  });
}

export const searchSupportersQuery = (searchTerm: string, page = 1, pageSize = 10) =>
  queryOptions({
    queryKey: ["supporters-search", { searchTerm: searchTerm, page, pageSize }],
    queryFn: async () => {
      const response = await api.supporter.searchSupporters({ page, pageSize, query: searchTerm });
      return {
        query: searchTerm,
        items: response.items,
        total_items: response.total_items,
      };
    },
    placeholderData: previousData => previousData,
  });

export const membersQuery = (supporterId: string) =>
  queryOptions({
    queryKey: ["supporters", supporterId, "members"],
    queryFn: async () => await api.supporter.getMembers({ supporterId: supporterId }),
    enabled: !!supporterId,
  });

export function useMembersQuery(supporterId: string | undefined) {
  return useQuery(membersQuery(supporterId!));
}

export const supporterWalletQuery = (supporterId: string) =>
  queryOptions({
    queryKey: ["supporters", supporterId, "wallet"],
    queryFn: async () => await api.supporter.getSupporterWallet({ supporterId: supporterId }),
  });

export function useSupporterWalletQuery(supporterId: string | undefined) {
  return useQuery({
    ...supporterWalletQuery(supporterId!),
    enabled: !!supporterId,
  });
}

export const supporterTokenMetadataQuery = (supporterId: string) =>
  queryOptions({
    queryKey: ["supporters", supporterId, "token-metadata"],
    queryFn: async () => {
      const [supporter, metadata] = await Promise.all([
        api.supporter.getSupporterWallet({ supporterId }),
        api.metadata.getTokensMetadata(),
      ]);
      const tokens = metadata.tokens;
      const currencyCode = supporter.wallet.currency;
      const tokenMetadata = tokens.find(token => token.code === currencyCode);

      if (!tokenMetadata) throw new Error("No token currency code found");

      return tokenMetadata;
    },
    enabled: supporterId !== undefined,
  });

export function useSupporterTokenMetadataQuery(supporterId: string) {
  return useQuery(supporterTokenMetadataQuery(supporterId));
}

// -------------------------------------------------------------------------------------------------

export function useUpdateSupporterMutation(supporterId: string) {
  return useMutation({
    mutationFn: async (body: UpdateSupporterBody) => {
      await api.supporter.updateSupporter({ supporterId, requestBody: body });
    },
  });
}

export function useSendInvitationsMutation() {
  return useMutation({
    mutationFn: async ({ supporterId, supporterMemberIds }: { supporterId: string; supporterMemberIds: string[] }) => {
      await api.supporter.sendMemberInvitations({
        supporterId,
        requestBody: { supporter_member_ids: supporterMemberIds },
      });
    },
    onError: error => {
      toast.error("Failed to send invitations", {
        description: getErrorMessage(error),
      });
    },
  });
}

export function useSendPasswordResetMutation() {
  return useMutation({
    mutationFn: async ({ supporterId, supporterMemberId }: { supporterId: string; supporterMemberId: string }) => {
      await api.supporter.sendPasswordReset({
        supporterId,
        supporterMemberId,
      });
    },
    onError: error => {
      toast.error("Failed to send passwordreset", {
        description: getErrorMessage(error),
      });
    },
  });
}

export function useCreateSupporterMemberMutation() {
  const client = useQueryClient();

  return useMutation({
    mutationFn: async (args: { supporterId: string; request: CreateSupporterMemberBody }) => {
      return await api.supporter.createSupporterMember({ supporterId: args.supporterId, requestBody: args.request });
    },
  });
}

export function useCreateSupporterMutation() {
  return useMutation({
    mutationFn: async (body: CreateSupporterBody) => api.supporter.createSupporter({ requestBody: body }),
  });
}

export const supporterAllowanceSettingsQuery = (supporterId: string) =>
  queryOptions({
    queryKey: ["supporter-allowance-settings", supporterId],
    queryFn: async () => {
      return await api.supporter.getSupporterAllowanceSettings({ supporterId });
    },
  });

export function useUpdateSupporterAllowanceSettingsMutation() {
  return useMutation({
    mutationFn: async ({ supporter_id, ...body }: UpdateSupporterAllowanceSettingsBody & { supporter_id: string }) => {
      return await api.supporter.updateSupporterAllowanceSettings({ supporterId: supporter_id, requestBody: body });
    },
  });
}

export const supporterBillingDetailsQuery = (supporterId: string) =>
  queryOptions({
    queryKey: ["supporter-billing-details", supporterId],
    queryFn: async () => {
      return await api.supporter.getSupporterBillingDetails({ supporterId });
    },
  });

export function useUpdateSupporterBillingDetailsMutation() {
  return useMutation({
    mutationFn: async (args: { supporterId: string; body: UpdateSupporterBillingDetailsBody }) => {
      return await api.supporter.updateSupporterBillingDetails({
        supporterId: args.supporterId,
        requestBody: args.body,
      });
    },
  });
}

export const supporterWalletTransactionsQuery = (supporterId: string, page: number, pageSize: number) =>
  queryOptions({
    queryKey: ["supporter-wallet-transactions", supporterId, page, pageSize],
    queryFn: async () => {
      return await api.supporterTransactions.getTransactionsForSupporter({ supporterId, pageNum: page, pageSize });
    },
  });

export const supporterWalletVersionTransactionsQuery = (supporterId: string, version: number) =>
  queryOptions({
    queryKey: ["supporter-wallet-version-transactions", supporterId, version],
    queryFn: async () => {
      return await api.supporterTransactions.getTransactionsForSupporterVersion({ supporterId, version });
    },
  });

export function useCreateSupporterMembersMutation(onSuccess: () => void, onError?: (error: any) => void) {
  const client = useQueryClient();
  return useMutation({
    mutationFn: async ({ supporterId, ...body }: { supporterId: string } & CreateSupporterMembersBody) => {
      return await api.supporter.createMembers({ supporterId, requestBody: body });
    },
    onSettled: (_, __, variables) => {
      client.invalidateQueries({ queryKey: ["supporters", variables.supporterId, "members"] });
    },
    onSuccess,
    onError,
  });
}

export function useRemoveSupporterMemberMutation() {
  const client = useQueryClient();
  return useMutation({
    mutationFn: async ({ supporterId, supporterMemberId }: { supporterId: string; supporterMemberId: string }) => {
      return await api.supporter.removeSupporterMember({ supporterId, supporterMemberId });
    },
    onError(error) {
      if (error instanceof ApiError && "code" in error.body && error.body.code === 2017) {
        toast.error("Cannot remove member with active allowance rules");
      } else {
        toast.error(getErrorMessage(error));
      }
    },
    onSettled: (_, __, variables) => {
      client.invalidateQueries({ queryKey: ["supporters", variables.supporterId, "members"] });
    },
  });
}

type SurplusStatus = Parameters<typeof api.supporter.getSupporterSurpluses>[0]["status"];

export function useSurplusesQuery(page = 1, pageSize = 10, status: SurplusStatus) {
  return useQuery({
    queryKey: ["surpluses", status, page, pageSize],
    queryFn: async () => {
      return await api.supporter.getSupporterSurpluses({ page, pageSize, status });
    },
  });
}

type SurplusActions = ActionSupporterSurplusBody["action"];

export function useActionSupporterSurplusMutation() {
  const client = useQueryClient();

  return useMutation({
    mutationFn: async (args: { surplusIds: string[]; action: SurplusActions }) => {
      await api.supporter.actionSupporterSurplus({
        requestBody: {
          surplus_ids: args.surplusIds,
          action: args.action,
        },
      });
    },
    onSuccess: () => {
      client.invalidateQueries({ queryKey: ["surpluses"] });
    },
  });
}

export function useSupporterStaffQuery(supporterId: string) {
  return useQuery({
    queryKey: ["supporters", supporterId, "staff"],
    queryFn: () => api.supporter.getSupporterStaff({ supporterId }),
    select: response => response.staff_users,
  });
}

export function useInviteSupporterStaffMutation() {
  const client = useQueryClient();
  return useMutation({
    mutationFn: async (args: { supporterId: string; request: InviteSupporterStaffBody }) => {
      return await api.supporter.inviteSupporterStaff({ supporterId: args.supporterId, requestBody: args.request });
    },
    onSuccess: (_, variables) => {
      client.invalidateQueries({ queryKey: ["supporters", variables.supporterId, "staff"] });
    },
  });
}

export function useDeleteSupporterStaffMutation() {
  const client = useQueryClient();

  return useMutation({
    mutationFn: async (args: { supporterId: string; userId: string }) => {
      await api.supporter.deleteSupporterStaff({ supporterId: args.supporterId, userId: args.userId });
    },
    onSuccess: (_, variables) => {
      client.invalidateQueries({ queryKey: ["supporters", variables.supporterId, "staff"] });
    },
  });
}

export function useResendSupporterStaffInvitationMutation() {
  return useMutation({
    mutationFn: async ({ supporterId, invitationId }: { supporterId: string; invitationId: string }) => {
      await api.supporter.resendSupporterStaffInvitation({ supporterId, invitationId });
    },
  });
}

export const supporterEAPSettingsQuery = (supporterId: string) =>
  queryOptions({
    queryKey: ["supporter-eap-settings", supporterId],
    queryFn: async () => {
      return await api.supporter.getSupporterEapSettings({
        supporterId: supporterId,
      });
    },
  });

export function useSupporterEAPSettingsMutation() {
  return useMutation({
    mutationFn: async ({ supporterId, body }: { supporterId: string; body: UpdateSupporterEapSettingsBody }) => {
      return await api.supporter.updateSupporterEapSettings({
        supporterId,
        requestBody: body,
      });
    },
  });
}
