import { createRoute, lazyRouteComponent } from "@tanstack/react-router";
import { valibotValidator } from "@tanstack/valibot-adapter";
import { fallback, object, optional, string } from "valibot";
import { adminRoute } from "..";

export const membersRoute = createRoute({
  path: "members",
  getParentRoute: () => adminRoute,
  beforeLoad: () => ({
    breadcrumb: "members",
  }),
});

const searchSchema = object({
  q: fallback(optional(string(), ""), ""),
});

export const membersIndexRoute = createRoute({
  path: "/",
  getParentRoute: () => membersRoute,
  component: lazyRouteComponent(() => import("./+MembersPage")),
  validateSearch: valibotValidator(searchSchema),
});
