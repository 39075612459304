import { createRoute, lazyRouteComponent, Outlet } from "@tanstack/react-router";
import { valibotValidator } from "@tanstack/valibot-adapter";
import { fallback, object, optional, picklist } from "valibot";
import { supplierRoute } from "..";

export const listingsRoute = createRoute({
  path: "listings",
  getParentRoute: () => supplierRoute,
  component: Outlet,
  beforeLoad: () => ({
    breadcrumb: "Listings",
  }),
});

const LISTING_STATUSES = ["closed", "declined", "draft", "pending", "published", "paused"] as const;

const searchSchema = object({
  status: fallback(optional(picklist(LISTING_STATUSES)), undefined),
});

export const listingsIndexRoute = createRoute({
  path: "/",
  getParentRoute: () => listingsRoute,
  component: lazyRouteComponent(() => import("./+ListingsPage")),
  validateSearch: valibotValidator(searchSchema),
});
