import { getRolesFromCache, isSupplierRole, isSupporterRole } from "@/api/roles";
import { useSupplierId, useSupporterId } from "@/utils/roles";

export function useIsAssumingRole(roleType: "admin" | "supplier" | "supporter") {
  const supplierId = useSupplierId();
  const supporterId = useSupporterId();
  const roles = getRolesFromCache()?.roles || [];

  const isAssuming =
    !roles.length ? false
    : roleType === "supplier" ? !roles.some(r => isSupplierRole(r) && r.supplier_id === supplierId)
    : roleType === "supporter" ? !roles.some(r => isSupporterRole(r) && r.supporter_id === supporterId)
    : false;

  return isAssuming;
}
