import { listingQuery, pendingListingsQuery } from "@/api/listings";
import { queryClient } from "@/api/utils";
import { createRoute, lazyRouteComponent, Outlet } from "@tanstack/react-router";
import { valibotValidator } from "@tanstack/valibot-adapter";
import { fallback, number, object, optional, pipe, string, toMinValue } from "valibot";
import { adminRoute } from "..";

export const listingReviewRoute = createRoute({
  path: "listing-review",
  getParentRoute: () => adminRoute,
  component: Outlet,
  beforeLoad: () => ({
    breadcrumb: "Listing Review",
  }),
});

const searchSchema = object({
  q: fallback(optional(string(), ""), ""),
  page: fallback(pipe(optional(number(), 1), toMinValue(1)), 1),
  "page-size": fallback(pipe(optional(number(), 100), toMinValue(10)), 100),
});

export const listingReviewIndexRoute = createRoute({
  path: "/",
  getParentRoute: () => listingReviewRoute,
  component: lazyRouteComponent(() => import("./+ListingReviewOverviewPage")),
  validateSearch: valibotValidator(searchSchema),
  loader: () => {
    queryClient.prefetchQuery(pendingListingsQuery(undefined));
  },
});

export const listingReviewDetailRoute = createRoute({
  path: "$listingId/$version",
  getParentRoute: () => listingReviewRoute,
  component: lazyRouteComponent(() => import("./+ListingReviewPage")),
  beforeLoad: () => ({
    breadcrumb: "Listing",
  }),
  parseParams: ({ version, ...params }) => {
    const num = +version;
    if (!isFinite(num)) {
      throw new Error("Invalid listing version");
    }
    return {
      ...params,
      version: Math.round(num),
    };
  },
  stringifyParams: ({ version, ...params }) => ({
    ...params,
    version: version.toString(),
  }),
  loader: ({ params }) => {
    queryClient.prefetchQuery(listingQuery(params.listingId, params.version));
  },
});
