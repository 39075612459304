import {
  BookingSupplierListingVariant,
  CitedRegistration,
  CreateListingRequest,
  EventSupplierListingVariant,
  GetSupplierListingResponse,
  PassSupplierListingVariant,
  ProductSupplierListingVariant,
  SubscriptionSupplierListingVariant,
  SupplierListingVariant,
  UpdateDraftListingRequest,
  UpdateSupplierSkuQuantitiesRequest,
} from "@givenwell/management-api";
import { queryOptions, useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { api } from "./utils";

export const pendingListingsQuery = (supplierId: string | undefined, page = 1, pageSize = 10) =>
  queryOptions({
    queryKey: ["pending-listings", { supplierId, page, pageSize }],
    queryFn: async () => {
      const response = await api.listings.getPendingListings({ page, pageSize, supplier: supplierId });
      return response;
    },
  });

export const listingQuery = (listingId: string, version: number) =>
  queryOptions({
    queryKey: ["listings", listingId, version],
    queryFn: async () => {
      const response = await api.listings.getSupplierListing({ listingId, version });
      return response;
    },
  });

export const listingHierarchyQuery = queryOptions({
  queryKey: ["listing-hierarchy"],
  queryFn: async () => {
    const response = await api.listings.getListingHierarchy();
    return response;
  },
});

export function useListingHierarchyQuery() {
  return useQuery(listingHierarchyQuery);
}

export function useVariantTypes() {
  return useQuery({
    queryKey: ["variant-types"],
    queryFn: () => {
      return [
        { id: "booking", name: "Booking" },
        { id: "event", name: "Event" },
        { id: "product", name: "Product" },
        { id: "pass", name: "Pass" },
        { id: "subscription", name: "Subscription" },
      ];
    },
  });
}

export const listingReviewQuery = (version: number, listingId: string) =>
  queryOptions({
    queryKey: ["listing-review", { version, listingId }],
    queryFn: async () => {
      const response = await api.listings.getListingReview({ listingId, version });
      return response;
    },
  });

export const registersQuery = (countryCode: string) =>
  queryOptions({
    queryKey: ["registers", countryCode],
    queryFn: async () => {
      const response = await api.listings.getRegisters({ country: countryCode });
      return response;
    },
  });

// -------------------------------------------------------------------------------------------------

export function useReviewListingMutation() {
  const client = useQueryClient();

  return useMutation({
    mutationFn: async (args: {
      listingId: string;
      version: number;
      action: "approve" | "reject";
      reason?: string;
      cited_registration?: CitedRegistration;
    }) => {
      return await api.listings.reviewPendingListing({
        listingId: args.listingId,
        version: args.version,
        requestBody: {
          cited_registration: args.cited_registration,
          review_type: args.action,
          reason: args.reason ?? "No reason given.",
        },
      });
    },
    onSuccess: () => {
      client.removeQueries({ queryKey: ["pending-listings"] });
      client.removeQueries({ queryKey: ["listings"] });
      client.removeQueries({ queryKey: ["listing"] });
    },
  });
}

export function useSaveDraftMutation(supplierId: string) {
  const client = useQueryClient();

  return useMutation({
    mutationFn: async (args: { listingId: string; version: number; request: UpdateDraftListingRequest }) => {
      return await api.listings.updateDraftListing({
        listingId: args.listingId,
        version: args.version,
        requestBody: args.request,
      });
    },
    onSuccess: () => {
      client.removeQueries({ queryKey: ["suppliers", supplierId, "listings"] });
      client.removeQueries({ queryKey: ["listings"] });
      client.removeQueries({ queryKey: ["listing"] });
    },
  });
}

export function useStartDraftMutation(supplierId: string) {
  const client = useQueryClient();

  return useMutation({
    mutationFn: async (args: { listingId: string }) => {
      return await api.listings.startDraftListingVersion({ listingId: args.listingId });
    },
    onSuccess: () => {
      client.removeQueries({ queryKey: ["suppliers", supplierId, "listings"] });
      client.removeQueries({ queryKey: ["listings"] });
      client.removeQueries({ queryKey: ["listing"] });
      client.removeQueries({ queryKey: ["auto-messages", "list", supplierId] });
    },
  });
}

export function useSubmitDraftForReviewMutation(supplierId: string) {
  const client = useQueryClient();

  return useMutation({
    mutationFn: async (args: { listingId: string; version: number }) => {
      return await api.listings.submitListingVersion({ listingId: args.listingId, version: args.version });
    },
    onSuccess: () => {
      client.removeQueries({ queryKey: ["suppliers", supplierId, "listings"] });
      client.removeQueries({ queryKey: ["listings"] });
      client.removeQueries({ queryKey: ["listing"] });
    },
  });
}

export function useUndoDraftSubmissionMutation(supplierId: string) {
  const client = useQueryClient();

  return useMutation({
    mutationFn: async (args: { listingId: string; version: number }) => {
      return await api.listings.cancelListingVersionSubmission({ listingId: args.listingId, version: args.version });
    },
    onSuccess: () => {
      client.invalidateQueries({ queryKey: ["listing"] });
      client.invalidateQueries({ queryKey: ["listings"] });
      client.removeQueries({ queryKey: ["suppliers", supplierId, "listings"] });
    },
  });
}

export function useCreateListingMutation(supplierId: string | undefined) {
  const client = useQueryClient();

  return useMutation({
    mutationFn: async (args: { request: CreateListingRequest }) => {
      return await api.listings.createListing({ requestBody: args.request });
    },
    onSuccess: () => {
      client.removeQueries({ queryKey: ["suppliers", supplierId, "listings"] });
      client.removeQueries({ queryKey: ["listings"] });
      client.removeQueries({ queryKey: ["listing"] });
    },
  });
}

export function useDeleteDraftMutation(supplierId: string | undefined) {
  const client = useQueryClient();

  return useMutation({
    mutationFn: async (args: { listingId: string; version: number }) => {
      return await api.listings.deleteListingVersion({ listingId: args.listingId, version: args.version });
    },
    onSuccess: () => {
      client.removeQueries({ queryKey: ["suppliers", supplierId, "listings"] });
      client.removeQueries({ queryKey: ["listings"] });
      client.removeQueries({ queryKey: ["listing"] });
    },
  });
}

export function usePauseListingMutation(supplierId: string) {
  const client = useQueryClient();

  return useMutation({
    mutationFn: async (args: { listingId: string }) => {
      return await api.listings.pauseListing({ listingId: args.listingId });
    },
    onSuccess: () => {
      // client.removeQueries({ queryKey: ["suppliers", supplierId, "listings"] });
      client.invalidateQueries({ queryKey: ["suppliers", supplierId, "listings"] });
      client.invalidateQueries({ queryKey: ["listing"] });
    },
  });
}

export function useResumeListingMutation(supplierId: string) {
  const client = useQueryClient();

  return useMutation({
    mutationFn: async (args: { listingId: string }) => {
      return await api.listings.resumeListing({ listingId: args.listingId });
    },
    onSuccess: () => {
      client.invalidateQueries({ queryKey: ["suppliers", supplierId, "listings"] });
      client.invalidateQueries({ queryKey: ["listings"] });
      client.removeQueries({ queryKey: ["listing"] });
    },
  });
}

export function useArchiveListingMutation(supplierId: string) {
  const client = useQueryClient();

  return useMutation({
    mutationFn: async (args: { listingId: string }) => {
      return await api.listings.archiveListing({ listingId: args.listingId });
    },
    onSuccess: () => {
      client.removeQueries({ queryKey: ["suppliers", supplierId, "listings"] });
      client.removeQueries({ queryKey: ["listings"] });
      client.removeQueries({ queryKey: ["listing"] });
    },
  });
}

// Typescript utils

export type Listing = GetSupplierListingResponse;
export type ListingStatus = Listing["status"];
export type ListingDeliveryMethod = NonNullable<Listing["delivery_method"]>;

export type BaseListingVariant = SupplierListingVariant;
export type ListingVariant = Listing["variants"][number];
export type VariantLocationType = NonNullable<ListingVariant["location_type"]>;
export type VariantType = ListingVariant["type_id"];

export function isBookingVariant(variant: BaseListingVariant): variant is BookingSupplierListingVariant {
  return variant.$type === "booking";
}
export function isProductVariant(variant: BaseListingVariant): variant is ProductSupplierListingVariant {
  return variant.$type === "product";
}
export function isEventVariant(variant: BaseListingVariant): variant is EventSupplierListingVariant {
  return variant.$type === "event";
}
export function isSubscriptionVariant(variant: BaseListingVariant): variant is SubscriptionSupplierListingVariant {
  return variant.$type === "subscription";
}
export function isPassVariant(variant: BaseListingVariant): variant is PassSupplierListingVariant {
  return variant.$type === "pass";
}

export function useSupplierSKUQuery(supplierId: string) {
  return useQuery({
    queryKey: ["suppliers", supplierId, "skus"],
    queryFn: async () => {
      return await api.listings.getSupplierSkus({ supplierId });
    },
  });
}

export function useUpdateSKUQuantitiesMutation() {
  const client = useQueryClient();

  return useMutation({
    mutationFn: async (args: { supplierId: string; changes: UpdateSupplierSkuQuantitiesRequest }) => {
      return await api.listings.updateSupplierSkuQuantities({ supplierId: args.supplierId, requestBody: args.changes });
    },
    onSettled: (_, __, variables) => {
      client.invalidateQueries({ queryKey: ["suppliers", variables.supplierId, "skus"] });
    },
  });
}
