import { centsToDollars } from "./currency";

// @ts-expect-error one day we will have a proper i18n setup
export const locale = "en-NZ" || navigator.languages[0] || navigator.language || "en-NZ";

const wholeNumberFormatter = new Intl.NumberFormat(locale, {
  maximumFractionDigits: 0,
});

export function formatWholeNumber(n: number | undefined): string {
  if (n === undefined) return "";

  return wholeNumberFormatter.format(n);
}

export function formatCents(cents: number, currency = "NZD", noSign = false) {
  const n = centsToDollars(cents, currency);

  const formatter = new Intl.NumberFormat(locale, {
    style: "currency",
    currency,
  });

  if (noSign) {
    const { minimumFractionDigits } = formatter.resolvedOptions();
    const noSignFormatter = new Intl.NumberFormat(locale, { minimumFractionDigits });
    return noSignFormatter.format(n);
  }

  return formatter.format(n);
}

export function formatDollars(dollars: number, currency = "NZD", options?: Intl.NumberFormatOptions) {
  const formatter = new Intl.NumberFormat(locale, {
    style: "currency",
    currency,
    ...options,
  });

  return formatter.format(dollars);
}

export function getCurrencySymbol(currency = "NZD") {
  const formatter = new Intl.NumberFormat(locale, {
    style: "currency",
    currency,
  });

  const parts = formatter.formatToParts(1);

  return parts.find(part => part.type === "currency")?.value || "";
}
